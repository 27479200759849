@import 'consts';

.btn {
    display: block;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    height: 48px !important;
    letter-spacing: 0 !important;
    text-decoration: none;
    text-align: center;
    border-radius: 6px !important;
    border-width: 2px !important;
    border-style: solid !important;
    cursor: pointer;

    &.primary {
        background-color: $Primary !important;
        color: $Lightness !important;
        border-color: $Primary !important;

        &:hover {
            background-color: $Lightness !important;
            color: $Primary !important;
            border-color: $Primary !important;
        }
    }

    &.primary-light {
        background-color: $PrimarySecondLight !important;
        color: $PrimarySecond !important;
        border-color: $PrimarySecondLight !important;

        &:hover {
            background-color: $Lightness !important;
            color: $PrimarySecond !important;
            border-color: $PrimarySecond !important;
        }
    }

    &.primary-second-light {
        background-color: transparent !important;
        color: $PrimarySecond !important;
        border-color: transparent !important;

        &:hover {
            background-color: $PrimarySecondLight !important;
            color: $PrimarySecond !important;
            border-color: $PrimarySecond !important;
        }
    }

    &.primary-dark {
        background-color: $TertiaryLightest !important;
        color: $TertiaryDarkest !important;
        border-color: $TertiaryLightest !important;

        &:hover {
            background-color: $Lightness !important;
            border-color: $TertiaryDarkest !important;
        }
    }

    &.basic {
        background-color: $Lightness !important;
        color: $TertiaryDarker !important;
        border-color: $TertiaryLightest !important;
        &:hover {
            background-color: $Lightness !important;
            color: $Primary !important;
            border-color: $Primary !important;
        }
    }

    &.basic-no-hover {
        background-color: $Lightness !important;
        color: $TertiaryDarker !important;
        border-color: $Lightness !important;
        pointer-events: none;
        &:hover {
            background-color: $Lightness !important;
            color: $TertiaryDarker !important;
            border-color: $Lightness !important;
            cursor: not-allowed;
        }
    }

    &.danger {
        background-color: $DangerLightest !important;
        color: $Danger !important;
        border-color: $DangerLightest !important;
        &:hover {
            background-color: $Lightness !important;
            border-color: $Danger !important;
        }
    }

    &.outline {
        background-color: transparent !important;
        border: 1px solid $Lightness !important;
        color: $Lightness !important;

        &-bordered {
            background-color: transparent !important;
            color: $TertiaryDarkest !important;
            border-radius: 4px !important;
            border: 1px solid $TertiaryLightest !important;
        }
    }

    &-dialog-close {
        padding: 0 55px;
        outline: none;
    }

    &.mat-button-disabled,
    &.mat-mdc-button-disabled,
    &:disabled {
        background-color: $TertiaryLightest !important;
        color: $Lightness !important;
        border-color: $TertiaryLightest !important;

        &:hover {
            background-color: $TertiaryLightest !important;
            color: $Lightness !important;
            border-color: $TertiaryLightest !important;
            cursor: not-allowed !important;
        }
    }
}
