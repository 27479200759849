$Lightness: var(--Lightness);
$TertiaryLightest: var(--TertiaryLightest);
$TertiaryLighter: var(--TertiaryLighter);
$Tertiary: var(--Tertiary);
$TertiaryDarker: var(--TertiaryDarker);
$TertiaryDarkest: var(--TertiaryDarkest);
$SuccessDarker: var(--SuccessDarker);
$DangerLightest: var(--DangerLightest);
$Danger: var(--Danger);
$WarningLightest: var(--WarningLightest);
$Warning: var(--Warning);
$PrimaryLightest: var(--PrimaryLightest);
$Primary: var(--Primary);
