@use '@angular/material' as mat;
@import 'consts';
@import 'screens';

@include mat.core();

mat.$theme-ignore-duplication-warnings: true;

// $white-app-theme: mat.define-light-theme((
//   color: (
//     primary: mat.define-palette(mat.$blue-palette, 700),
//     accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
//     warn: mat.define-palette(mat.$red-palette),
//   )
// ));

$default-app-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$blue-palette, 700),
    accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette),
  )
));

$tnt-app-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$cyan-palette, 800),
    accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette),
  )
));


$trackipet-app-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$green-palette, 800),
    accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette),
  )
));

$myloc8-app-theme: mat.define-light-theme((
  color: (
    // primary: mat.define-palette(mat.$blue-grey-palette, 800),
    // accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
    // warn: mat.define-palette(mat.$red-palette),
    primary: mat.define-palette(mat.$green-palette, 800),
    accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette),
  )
));

@include mat.all-component-themes($default-app-theme);

.tagandtrack {
    @include mat.all-component-themes($tnt-app-theme);
}

.trackipet {
    @include mat.all-component-themes($trackipet-app-theme);
}

.myloc8 {
    @include mat.all-component-themes($myloc8-app-theme);

    .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after {
        background: #aeb4ba !important;
    }

    .dashboard, .auth-container {
        .sidenav {
            /* Change the border color */
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label, 
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label--float-above,
            .mat-mdc-form-field-input-control::placeholder,
            .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
            .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input,
            .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
            .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
            .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle,
            .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
            .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
            .mat-mdc-select-value,
            .mdc-form-field>label,
            .mat-mdc-select-value-text,
            .mat-mdc-checkbox label {
                border-color: white !important;
                color: white !important;
            }
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
                color: #929DBA;
            }
            .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
                color: #f44336 !important;
            }
        }
    }
}

.petloc8 {
    @include mat.all-component-themes($trackipet-app-theme);
    
    .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after {
        background: #aeb4ba !important;
    }
    .dashboard:not(.multimap), .auth-container {
        /* Change the border color */
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label, 
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label--float-above,
        .mat-mdc-form-field-input-control::placeholder,
        .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
        .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input,
        .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
        .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
        .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle,
        .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle,
        .mat-mdc-select-value,
        .mdc-form-field,
        .mdc-form-field>label,
        .mat-mdc-select-value-text,
        .mat-mdc-checkbox label {
            border-color: white !important;
            color: white !important;
        }
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
        .mat-mdc-form-field.mat-form-field-disabled .mat-mdc-input-element,
        .mat-mdc-form-field.mat-form-field-disabled mdc-floating-label,
        .mdc-text-field--outlined.mdc-text-field--disabled mat-label, 
        .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
        .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
        .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
            border-color: #929DBA !important;
            color: #929DBA !important;
        }
        
        .mdc-form-field>label {
            color: white !important;
        }

        .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
            color: #f44336 !important;
        }
    }
}

.mat-horizontal-stepper-header {
    pointer-events: none;
}

.form-field-custom {
    .mat-form-field-infix {
        padding: 0.5em 0 0.9em 0;
    }

    .mat-select-arrow-wrapper {
        transform: none !important;
    }

    input.mat-input-element {
        font-size: 16px;
    }

    .mat-form-field-label-wrapper {
        top: -20px;
        padding-top: 0.84375em;
        font-size: 16px;
    }
}

// .mat-mdc-dialog-container {
//     overflow-x: hidden !important;
// }

.no-scroll {
    overflow: hidden;
}

//modal with for mobile override
@include mobile-only-md() {
    .cdk-global-overlay-wrapper {
        .cdk-overlay-pane {
            width: 95% !important;
            max-width: 95vw !important;
        }
    }
}

.menu-device {
    .mat-menu-content {
        padding-top: 14px !important;
    }
}

// override table header
.mat-header-cell {
    color: $TertiaryDarker;
}

