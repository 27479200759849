@import './skins/default';
@import './skins/trackimo';
@import './skins/tnt';
@import './skins/trackipet';

// default colors
body {
    --Lightness: #ffffff;
    --TertiaryLightest: #e5e7e9;
    --TertiaryLighter:	#aeb4ba;
	--Tertiary: #75808a;
    --TertiaryDarker: #464d53;
    --TertiaryDarkest: #171a1c;
    --SuccessDarker: #0da734;
    --DangerLightest: #ffcece;
	--Danger: #ff0000;
    --WarningLightest: #fbe4d4;
    --Warning: #ff6b00;
    --PrimaryLighter: #72b4f5;
    --PrimaryLightest: #d1e7fc;
    --Primary: #1181ef;
    --VodafonePrimary: #e60000;
    --VodafoneSecondary: #007c92; 
    --VodafoneSecondaryLighter: #95c4cd;
    --VodafoneSecondaryLightest: #ebf2f4;

    --TrackiPetPrimary: #009C56;
    --TrackiPetSecondary: #009C56;
    --TrackiPetPrimaryLightest: #8FEBA2;
    --TrackiPetPrimaryFifth: #68D37F;

    --FidelidadePrimary: #EE2429;

    --PetLoc8Primary: #21293D;

    --MyLoc8Primary: #303D64;
    --MyLoc8Tertiary: #929DBA;
    --MyLoc8SecondaryLightest: #929DBA;
}

body.trackimo {
    --primary: var(--Primary);
    --primary-second: var(--Primary);
    --primary-second-light: var(--PrimaryLightest);
    --primary-third: var(--Primary);
    --primary-fifth: var(--PrimaryLighter);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.tagandtrack {
    --primary: var(--VodafonePrimary);
    --primary-second: var(--VodafoneSecondary);
    --primary-second-light: var(--VodafoneSecondaryLightest);
    --primary-third: var(--TertiaryDarkest);
    --primary-fifth: var(--VodafoneSecondaryLighter);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.mamori {
    --primary: var(--Primary);
    --primary-second: var(--Primary);
    --primary-second-light: var(--PrimaryLightest);
    --primary-third: var(--Primary);
    --primary-fifth: var(--PrimaryLighter);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.mytracki {
    --primary: var(--Primary);
    --primary-second: var(--Primary);
    --primary-second-light: var(--PrimaryLightest);
    --primary-third: var(--Primary);
    --primary-fifth: var(--PrimaryLighter);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.trackipet {
    --primary: var(--TrackiPetPrimary);
    --primary-second: var(--TrackiPetSecondary);
    --primary-second-light: var(--TrackiPetPrimaryLightest);
    --primary-third: var(--TrackiPetPrimary);
    --primary-fifth: var(--TrackiPetPrimaryFifth);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.watchinu {
    --primary: var(--Primary);
    --primary-second: var(--Primary);
    --primary-second-light: var(--PrimaryLightest);
    --primary-third: var(--Primary);
    --primary-fifth: var(--PrimaryLighter);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.tracki {
    --primary: var(--Primary);
    --primary-second: var(--Primary);
    --primary-second-light: var(--PrimaryLightest);
    --primary-third: var(--Primary);
    --primary-fifth: var(--PrimaryLighter);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.fidelidade {
    --primary: var(--FidelidadePrimary);
    --primary-second: var(--VodafoneSecondary);
    --primary-second-light: var(--VodafoneSecondaryLightest);
    --primary-third: var(--TertiaryDarkest);
    --primary-fifth: var(--VodafoneSecondaryLighter);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}

body.petloc8 {
    --primary: var(--PetLoc8Primary);
    --primary-second: var(--MyLoc8Primary);
    --primary-second-light: var(--MyLoc8SecondaryLightest);
    --tertiary-lightest: var(--TertiaryLightest);
}

body.myloc8 {
    --primary: var(--MyLoc8Primary);
    --primary-second: var(--MyLoc8Primary);
    --primary-second-light: var(--MyLoc8SecondaryLightest);
    --primary-third: var(--Primary);
    --primary-fifth: var(--PrimaryLighter);
    --Tertiary: var(--MyLoc8Tertiary);
    --tertiary-lightest: var(--TertiaryLightest);
    --tertiary-darkest: var(--TertiaryDarkest);
    --tertiary-darker: var(--TertiaryDarker);
}