.marker {
    cursor: pointer;
    height: 116px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &.hidden {
        display: none;
    }

    &-time, .live-tracking, .no-movement, .sleep-mode, .shut-down, .tap-to-locate {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px;
        background: $PrimarySecond;
        border-radius: 2px;
        color: $Lightness;
        width: fit-content;
        margin: 0 auto;

        &.red-marker {
            background-color: $Danger;
        }

        &.inactive-marker {
            background-color: $Tertiary;
        }

        &.online-mode-marker {
            background-color:#009C56;
        }

        &.shut-down {
            background-color: #8E8E93;
        }

        .refresh {
            margin-right: 5px;
        }

        .low-battery {
            margin-left: 5px;
        }
    }

    .live-tracking {
        background-color: #FF6B00;
    }

    .no-movement {
        background-color: #F2C111;
    }

    .tap-to-locate {
        background-color: #C64AF4;
    }

    .sleep-mode {
        background-color: #161390;
    }

    &-image-container {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 5px auto;
        width: 65px;

        .connection-type {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $Lightness;
            border: 1px solid $PrimarySecond;
            position: absolute;
            right: 7px;
            top: -2px;
            z-index: 1;

            &.inactive-marker {
                border-color: $Tertiary;

                img {
                    filter: invert(69%) sepia(5%) saturate(20%) hue-rotate(23deg) brightness(88%)
                        contrast(93%);
                }
            }

            &.red-marker {
                border-color: $Danger;
                img {
                    filter: invert(50%) sepia(88%) saturate(4266%) hue-rotate(334deg) brightness(96%)
                        contrast(98%);
                }
            }

            &.no-movement-marker {
                border-color: #F2C111;
                img {
                    filter: invert(100%) sepia(100%) saturate(266%) hue-rotate(354deg) brightness(106%);
                }
            }

            &.tap-to-locate-marker {
                border-color: #C64AF4;

                img {
                    filter: invert(100%) sepia(100%) saturate(266%) hue-rotate(354deg) brightness(106%);
                }
            }

            img {
                width: 12px;
                height: 12px;
            }
        }

        .m-image {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            top: 6px;

            &.red-marker {
                filter: invert(50%) sepia(88%) saturate(4266%) hue-rotate(334deg) brightness(96%)
                    contrast(98%);
            }

            &.inactive-marker {
                filter: invert(69%) sepia(5%) saturate(20%) hue-rotate(23deg) brightness(88%) contrast(93%);
            }
        }
    }

    &-name {
        padding: 2px 6px;
        font-weight: bold;
        text-align: center;
        font-size: 10px;
        line-height: 15px;
        color: $Tertiary;
        background-color: $Lightness;
        border-radius: 2px;
        box-shadow: 0px 1px 6px rgba(99, 99, 99, 0.2);
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.user-pin {
    height: 30px;
    width: 30px;
    background-color: $PrimarySecond;
    border-radius: 50%;
    border: 6px solid $PrimaryFifth;
    box-sizing: border-box;

    &.hidden {
        display: none;
    }
}

// infowindow restyle
.gm-style-iw-tc {
    display: none;
}
.gm-style-iw-t {
    bottom: 6px !important;
}
.gm-style-iw-t::after {
    display: none;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: $PrimarySecond !important;
}

.gm-style-iw {
    height: 22px;
    border-radius: 2px !important;
    padding: 0 !important;
    cursor: pointer !important;
    .gm-style-iw-d {
        overflow: hidden !important;
    }
    .zone-title {
        max-width: 100px;
        font-size: 12px;
        line-height: 15px;
        padding: 4px 12px;
        margin: 0;
        color: $Lightness;

        &.polygon {
            background-color: #FFBD13;
        }
        &.wifi_geozone {
            background-color: #161390;
        }
        &.polyline {
            background-color: #610ACF;
        }
    }
    button {
        display: none !important;
    }
}
