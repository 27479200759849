$screen-xs-min: 320px; // Extra small screen / phone
$screen-xs-md: 470px; // Medium extra small screen
$screen-sm-min: 768px; // Small screen / tablet
$screen-md-min: 960px; // Medium screen / desktop
$screen-md-xl-min: 1100px; // Medium screen / desktop
$screen-lg-min: 1280px; // Large screen / desktop

$screen-height-sm-min: 665px; // Medium screen / desktop

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);

@mixin tablet {
    @media (min-width: $screen-sm-min) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $screen-md-min) {
        @content;
    }
}

@mixin under-desktop {
    @media (max-width: $screen-md-min) {
        @content;
    }
}

@mixin under-wide-desktop {
    @media (max-width: $screen-md-xl-min) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: $screen-lg-min) {
        @content;
    }
}

@mixin tablet-only {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin tablet-only-portrait {
    @media (min-device-width: $screen-sm-min) and (max-device-width: $screen-sm-max) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-only-landscape {
    @media (min-device-width: $screen-sm-min) and (max-device-width: $screen-sm-max) and (orientation: landscape) {
        @content;
    }
}

@mixin mobile-only {
    @media (max-width: $screen-xs-max) {
        @content;
    }
}

@mixin mobile-only-md {
    @media (max-width: $screen-xs-md) {
        @content;
    }
}

@mixin mobile-landscape {
    @media (max-width: $screen-sm-max) and (orientation: landscape) {
        @content;
    }
}

@mixin mobile-extra-small {
    @media (max-width: $screen-xs-min) {
        @content;
    }
}

@mixin orientation-landscape {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

@mixin height-sm {
    @media (max-height: $screen-height-sm-min) {
        @content;
    }
}
